/* BEGIN Bottom Bar Vermont Web Standards CSS */
.footer {
  text-align: center;
  height: 35px;
  width: 100%;
}

.bottom-bar-outer-container {
  width: 100%;
  max-width: none;
  background-color: #003300;
  margin: 0;
  padding: 0;
  min-height: 35px;
}

.bottom-bar-inner-container {
  background-color: #003300;
}

.bottom-row-text {
  font-family: "Lato", Verdana, Tahoma, "DejaVu Sans", sans-serif;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.85);
}

.bottom-row-link {
  border-bottom-color: rgba(255, 255, 255, 0.4);
  border-bottom-style: dotted;
  border-bottom-width: 1px;
}

.bottom-row-link:hover {
  text-decoration-line: none;
  border-bottom-color: rgba(255, 255, 255);
  color: rgb(255, 255, 255);
}

.bottom-copyright {
  margin: 0 6px;
}

.bottom-policies {
  margin: 0 6px;
}

@media screen and (max-width: 750px) {
  .bottom-row-link {
    font-size: 10px;
  }
  .bottom-row-text {
    font-size: 10px;
    margin-left: 10px
  }
}

@media screen and (min-width: 750px) {

}
@media screen and (min-width: 1200px) {

}


/* END Bottom Bar Vermont Web Standards CSS */
