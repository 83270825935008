body {
  margin: 0;
  padding: 0;
  font-family: Source Sans Pro, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow:hidden
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.body-content {
  display: flex;
  flex-direction: row;
  background-color: #f8f8f8;
  align-items: center;
  justify-content: center;
  /* padding-bottom: 35px; */
}

.body-content {
  min-height: calc(100vh - 171px);
  align-items: stretch
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 1);
}

@media screen and (max-width: 750px) {
  .body-content {
    min-height: calc(100vh - 70px);
  }

}

@media screen and (min-width: 750px) {
  .body-content {
    min-height: calc(100vh - 175px);
  }
}
@media screen and (min-width: 1200px) {
  .body-content {
    height: calc(100vh - 221px);
  }
}
