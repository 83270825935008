
  
  /* START Top Navigation Vermont Web Standards CSS */

  /* Import Fonts */
  @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
  
  .table-wrapper {
    overflow-x: auto;
    display: block;
  }
  
  /* .box-shadow {
    box-shadow: 0 .4rem .4rem rgba(0, 0, 0, .03);
  } */
  
  .top-bar-outer-container {
    width: 100%;
    max-width: none;
    background-color: #003300;
    margin: 0;
    padding: 0;
  }
  
  .top-bar-inner-container {
    background-color: #003300;
    margin: 0px 50px;
  }
  
  .vermont-official-row {
    text-align: left;
    font-weight: 300;
    line-height: 75%;
    display: flex;
    justify-content: space-between;
  }
  
  .vermont-text-official {
    display: flex;
    align-items: center;
  }

  .vermont-logo-official {
    display: flex;
    align-items: center;
    margin-right: -30px;
  }

  .vermont-official {
    color: white;
    font-family: "Source Sans Pro", Verdana, Tahoma, "DejaVu Sans", sans-serif;
    padding-left: 0.2em;
    text-decoration: none;
    /* font-size: 10px; */
  }
  
  .vermont-official:hover {
    color: white;
    text-decoration: none;
  }
  
  .vermont-logo {
    background-image: url("../assets/vermont-logo.svg");
    background-color: transparent;
    background-size: contain;
    display: block;
    float: right;
    background-image: "";
    background-repeat: no-repeat;
    height: 20px
  }
  
  .site-name-outer-container {
    width: 100%;
    max-width: none;
    background-color: #ffffff;
    margin: 0;
    padding: 0px 0px 6px 0px;
    /* border-bottom:  solid #dcdee0; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .site-name-inner-container {
    /* min-height: 94px; */
    background-color: #ffffff;
    margin: 0px 50px;
  }

  .nav-button-container {
    /* border-bottom:  solid #dcdee0; */
    padding: 0px 40px 5px 0px;
    margin-top: 32px;
  }   

  .nav-button{ 
    margin-left: 10px !important;
  }

  .MuiButton-contained {
    background-color: rgb(110, 110, 110) !important;
    padding: 4px 10px !important;
    font-size: 0.8125rem !important;
    border-radius: 4px !important;
    color: #fff !important;
  }

  .MuiButton-outlined {
    color: rgb(110, 110, 110) !important;
    border: 1px solid rgba(110, 110, 110, 0.5) !important;
    padding: 3px 9px !important;
    font-size: 0.8125rem !important;
    border-radius: 4px !important;
  }
  
  .site-name-row {
    background-color: #ffffff;
    display: block;
    padding: 20px 0px 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: left;
  }
  
  .site-name {
    font-family: "Open Sans", Verdana, Tahoma, "DejaVu Sans", sans-serif;
    font-weight: 400;
    color: #030;
    padding-left: 0.2em;
    font-size: 20px;
  }
  
  .site-subname {
    font-family: "Open Sans", Verdana, Tahoma, "DejaVu Sans", sans-serif;
    font-weight: 700;
    color: #030;
    line-height: 118%;
    padding: 0.2em 0px 0px 0.2em;
    /* padding-left: 0.2em; */
    text-decoration: none;
  }
  
  .site-subname:hover {
    color: #3e6839;
    /* text-decoration: solid; */
  }
  
  .top-navigation-row {
    min-height: 50px;
    background-color: #efefe3;
  }
  
  .top-navigation-bar {
    background-color: #efefe3;
  }
  
  .top-navigation-link {
    color: #333333;
    font-family: "Lato", Verdana, Tahoma, "DejaVu Sans", sans-serif;
    cursor: pointer;
  }
  
  .top-navigation-link:hover {
    background-color: #dcdcc4;
  }
  
  .top-navigation-link-highlighted {
    background-color: #dcdcc4;
  }
  
  .top-navigation-dropdown {
    color: #333333;
    font-family: "Lato", Verdana, Tahoma, "DejaVu Sans", sans-serif;
    cursor: pointer;
  }
  
  .top-navigation-dropdown:hover {
    background-color: #dcdcc4;
  }
  
  .top-navigation-dropdown-menu {
    background-color: #efefe3;
  }
  
  .top-navigation-dropdown-link {
    color: #333333;
    font-family: "Lato", Verdana, Tahoma, "DejaVu Sans", sans-serif;
    cursor: pointer;
  }
  
  .top-navigation-dropdown-link:hover {
    background-color: #dcdcc4;
  }
  
  .navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 1);
  }
  
  @media screen and (max-width: 750px) {
    .vermont-logo {
      height: 16px;
      width: 130px;
    }
    .vermont-official-row {
      height: 26px;
    }
    .vermont-official {
      font-size: 10px;
    }
    .site-name {
      font-size: 12.8px;
    }
    .site-subname {
      font-size: 25.6px;
    }
    .top-navigation-link {
      font-size: 12.8px;
    }
    .top-navigation-dropdown {
      font-size: 12.8px;
    }
    .top-navigation-dropdown-link {
      font-size: 12.8px;
    }
    .site-name-row {
      padding-top: 16px;
    }
    .bottom-row-text {
      font-size: 12px;
    }
    h5 {
      font-size: 1.15rem;
    }
    .site-name-inner-container {
      display: none;
    }
    .nav-button-container{
      display: none
    }
    .mainContainer {
      padding: 4px 8px !important;
    }
  }
  
  @media screen and (min-width: 750px) {
    .vermont-logo {
      height: 20px;
      width: 189px;
    }
    .vermont-official-row {
      height: 46px;
    }
    .vermont-official {
      font-size: 14px;
    }
    .site-name {
      font-size: 12.8px;
    }
    .site-subname {
      font-size: 20px;
    }
    .top-navigation-link {
      font-size: 12.8px;
    }
    .top-navigation-dropdown {
      font-size: 12.8px;
    }
    .top-navigation-dropdown-link {
      font-size: 12.8px;
    }
    .bottom-row-text {
      font-size: 12px;
    }
    .toast {
      min-width: 615px !important;
    }
    /* .tap-summaries {
      display: none;
    } */
  }
  
  @media screen and (min-width: 1200px) {
    .vermont-official {
      font-size: 1rem;
    }
    
    .top-bar-inner-container {
      margin: 0px 40px;
    }
    .site-name-inner-container {
      margin: 0px 40px;
    }
    .site-name {
      font-size: 16px;
    }
    .site-subname {
      font-size: 22px;
    }
    .top-navigation-link {
      font-size: 14.4px;
    }
    .top-navigation-dropdown {
      font-size: 14.4px;
    }
    .top-navigation-dropdown-link {
      font-size: 14.4px;
    }
    .bottom-row-text {
      font-size: 13.5px;
    }
  }
  
  .navDivider {
    border-right: 1px solid silver;
    margin-left: 0.5rem;
  }
  
  .passAlert {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* .form-control { */
  
  /* display: inline !important; */
  
  /* } */
  
  .passButton {
    display: inline;
    margin-left: 1rem;
    margin-top: 1rem;
  }
  
  /* END Top Navigation Vermont Web Standards CSS */
  

  
 